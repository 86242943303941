import { useEffect, useState } from 'react'

import { Metadata, useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Card from 'src/components/Custom/Card/Card'
import ErrorMessage from 'src/components/Custom/ErrorMessage/ErrorMessage'
import HeadingLevel from 'src/components/Custom/Heading/Heading'
import Loader from 'src/components/Custom/Loader/Loader'
import useUserStation from 'src/lib/hooks/useUserStation'
import useWarnUnsavedChanges from 'src/lib/hooks/useWarnUnsavedChanges'
import { StationUrlProfiles } from 'src/lib/urls'

import About, { AboutData } from './About/About'
import Branding, { BrandingData } from './Branding/Branding'
import ContactDetails, {
  ContactDetailsData,
} from './ContactDetails/ContactDetails'
import DonationUrls, { DonationUrlsData } from './DonationUrls/DonationUrls'
import Links, { LinksData } from './Links/Links'
import { GET_STATION, UPDATE_STATION } from './queries'
import WebPortalLinks, {
  WebPortalLinksData,
  WebPortalLinksProps,
} from './WebPortal/WebPortalLinks'

const data = [
  {
    title: 'About page/card',
    hideSeparator: false,
    content: (props) => <About {...props} />,
  },
  {
    title: 'Branding',
    hideSeparator: false,
    content: (props) => <Branding {...props} />,
  },
  {
    title: 'Contact Details',
    hideSeparator: false,
    content: (props) => <ContactDetails {...props} />,
  },
  {
    title: 'Links',
    hideSeparator: false,
    content: (props) => <Links {...props} />,
  },
  {
    title: 'Donation URLs',
    hideSeparator: false,
    content: (props) => <DonationUrls {...props} />,
  },
  {
    title: 'Web Portal Links',
    hideSeparator: false,
    content: (props: WebPortalLinksProps) => <WebPortalLinks {...props} />,
  },
]

interface LocalStationManagerState {
  aboutPage: AboutData
  branding: BrandingData
  contactDetails: ContactDetailsData
  donationUrls: DonationUrlsData
  links: LinksData
  webPortalLinks: WebPortalLinksData
}

type LocalStationManagerProps = {
  id?: string
}

const LocalStationManager = ({ id }: LocalStationManagerProps) => {
  const [state, setState] = useState<LocalStationManagerState | null>(null)
  const [showNewUser] = useState(false)
  const [invalidStation, setInvalidStation] = useState(false)

  const setUnsaved = useWarnUnsavedChanges()

  const userStationId = useUserStation()
  const stationId = id ?? userStationId

  const { data: stationData, loading: loadingStation } = useQuery(GET_STATION, {
    variables: { id: stationId },
  })

  const [updateStation, { loading: loadingUpdateStation }] = useMutation(
    UPDATE_STATION,
    {
      onCompleted: () => {
        toast.success('Update Successful')
      },
      onError: () => {
        toast.error('Error updating the station')
      },
    }
  )

  const handleStationUpdate = async (inputData) => {
    await updateStation({
      variables: {
        id: stationId,
        input: inputData,
      },
    })
  }

  useEffect(() => {
    setInvalidStation(stationData?.station === null)
  }, [stationData])

  useEffect(() => {
    if (stationData && stationData.station) {
      const findUrl = (profile: string): string | undefined => {
        return stationData.station.urls.find(
          (url: { profile: string; url: string }) => url.profile === profile
        )?.url
      }

      const newState = {
        aboutPage: {
          name: stationData.station.name,
          about: stationData.station.about,
          images: stationData.station.images,
        },
        branding: {
          colors: [
            {
              label: 'Primary',
              labelTooltip:
                'The primary color is used for buttons and menu/thumbnail selection indicators.',
              color: stationData.station.colorPrimary
                ? stationData.station.colorPrimary
                : '#FFFFFF',
              id: 'primary',
            },
            {
              label: 'Secondary',
              labelTooltip:
                'The secondary color is used for menu backgrounds and must contrast against the primary color.',
              color: stationData.station.colorSecondary
                ? stationData.station.colorSecondary
                : '#FFFFFF',
              id: 'secondary',
            },
            {
              label: 'Background',
              labelTooltip:
                'The background color is used for the app background and can be a solid shade or gradient.',
              color: stationData.station.colorBackground
                ? stationData.station.colorBackground
                : '#FFFFFF',
              id: 'background',
            },
          ],
          images: stationData.station.images,
        },
        contactDetails: {
          email: stationData.station.email,
          telephone: stationData.station.phone,
          fax: stationData.station.fax,
          addressStreet: stationData.station.addressStreet,
          addressCity: stationData.station.addressCity,
          addressState: stationData.station.addressState,
          addressZip: stationData.station.addressZip,
          [StationUrlProfiles.FACEBOOK]: findUrl(StationUrlProfiles.FACEBOOK),
          [StationUrlProfiles.TWITTER]: findUrl(StationUrlProfiles.TWITTER),
          [StationUrlProfiles.INSTAGRAM]: findUrl(StationUrlProfiles.INSTAGRAM),
          [StationUrlProfiles.TIKTOK]: findUrl(StationUrlProfiles.TIKTOK),
          [StationUrlProfiles.YOUTUBE]: findUrl(StationUrlProfiles.YOUTUBE),
          [StationUrlProfiles.MASTODON]: findUrl(StationUrlProfiles.MASTODON),
          urls: stationData.station.urls,
        },
        links: {
          [StationUrlProfiles.CONTACT]: findUrl(StationUrlProfiles.CONTACT),
          [StationUrlProfiles.DONATE]: findUrl(StationUrlProfiles.DONATE),
          [StationUrlProfiles.HELP]: findUrl(StationUrlProfiles.HELP),
          [StationUrlProfiles.PASSPORT]: findUrl(StationUrlProfiles.PASSPORT),
          [StationUrlProfiles.PRIVACY_POLICY]: findUrl(
            StationUrlProfiles.PRIVACY_POLICY
          ),
          [StationUrlProfiles.TERMS_OF_USE]: findUrl(
            StationUrlProfiles.TERMS_OF_USE
          ),
          urls: stationData.station.urls,
        },
        donationUrls: {
          [StationUrlProfiles.DONATE_URL_ANDROID]: findUrl(
            StationUrlProfiles.DONATE_URL_ANDROID
          ),
          [StationUrlProfiles.DONATE_URL_FIRETV]: findUrl(
            StationUrlProfiles.DONATE_URL_FIRETV
          ),
          [StationUrlProfiles.DONATE_URL_GOOGLETV]: findUrl(
            StationUrlProfiles.DONATE_URL_GOOGLETV
          ),
          [StationUrlProfiles.DONATE_URL_IOS]: findUrl(
            StationUrlProfiles.DONATE_URL_IOS
          ),
          [StationUrlProfiles.DONATE_URL_LGTV]: findUrl(
            StationUrlProfiles.DONATE_URL_LGTV
          ),
          [StationUrlProfiles.DONATE_URL_ROKU]: findUrl(
            StationUrlProfiles.DONATE_URL_ROKU
          ),
          [StationUrlProfiles.DONATE_URL_SAMSUNGTV]: findUrl(
            StationUrlProfiles.DONATE_URL_SAMSUNGTV
          ),
          [StationUrlProfiles.DONATE_URL_TVOS]: findUrl(
            StationUrlProfiles.DONATE_URL_TVOS
          ),
          [StationUrlProfiles.DONATE_URL_WEB]: findUrl(
            StationUrlProfiles.DONATE_URL_WEB
          ),
          urls: stationData.station.urls,
        },
        webPortalLinks: {
          links: stationData.station.webPortalLinks || [],
        },
      }
      setUnsaved(true)
      setState(newState)
    }
  }, [showNewUser, stationData, setUnsaved])

  const stateProperties = [
    { data: state?.aboutPage, key: 'aboutPage', type: 'AboutData' },
    { data: state?.branding, key: 'branding', type: 'BrandingData' },
    {
      data: state?.contactDetails,
      key: 'contactDetails',
      type: 'ContactDetailsData',
    },
    { data: state?.links, key: 'links', type: 'LinksData' },
    {
      data: state?.donationUrls,
      key: 'donationUrls',
      type: 'DonationUrlsData',
    },
    {
      data: state?.webPortalLinks,
      key: 'webPortalLinks',
      type: 'WebPortalLinksData',
    },
  ]

  const stateToProp = (index: number) => {
    const { data, key } = stateProperties[index]
    return {
      data,
      onChange: (dataToUpdate) => {
        setState((oldValue) => ({
          ...oldValue,
          [key]: dataToUpdate,
        }))
      },
    }
  }

  const isStateNull = () => {
    return state !== null && stationData !== undefined
  }

  return (
    <div className="w-full bg-gray-100 p-4">
      {loadingStation || loadingUpdateStation ? (
        <Loader />
      ) : invalidStation ? (
        <ErrorMessage
          title="Station Not Found"
          subtitle={
            <p>
              Station with ID <code>{id}</code> not found.
            </p>
          }
        />
      ) : (
        <>
          <Metadata title="Station Setings" />
          <HeadingLevel level={1} className="mb-4">
            Station Settings
          </HeadingLevel>
          {isStateNull() && id && (
            <HeadingLevel level={2}>{state.aboutPage.name}</HeadingLevel>
          )}

          <div className="mt-5 space-y-6">
            {isStateNull() &&
              data.map((d, index) => (
                <Card key={d.title} {...d}>
                  {d.content({
                    ...stateToProp(index),
                    updateStation: handleStationUpdate,
                    loadingUpdateStation: loadingUpdateStation,
                  })}
                </Card>
              ))}
          </div>
        </>
      )}
    </div>
  )
}

export default LocalStationManager
