import { useState } from 'react'

import { Tab, TabGroup, TabList } from '@headlessui/react'

import {
  FieldError,
  Form,
  Label,
  Submit,
  TextAreaField,
  TextField,
  useForm,
} from '@redwoodjs/forms'

import { LivestreamInputType } from 'src/common/types'
import {
  embedCodeFieldDescription,
  pbsStationManagerLivestreamFeedCidFieldDescription,
  sourceUrlFieldDescription,
  vimeoLiveEventIdFieldDescription,
} from 'src/pages/LocalStationManager/Live/constants'

import Button from '../Button/Button'
import DragAndDropImageArea from '../DragAndDropImageArea/DragAndDropImageArea'
import Loader from '../Loader/Loader'

import CustomModal from './CustomModal'

type SourceTabsArray = {
  id: number
  label: string
  name: string
}

interface ConfigureLivestreamModalProps {
  showModal: boolean
  handleModalClose: () => void
  onSave: (inputData: LivestreamInputType) => Promise<void>
  sourceTabs: SourceTabsArray[]
  classNames: (...classes: (string | undefined | null | boolean)[]) => string
  uploadImage: (files: File[]) => Promise<string>
  stationId: string
  loadingCreateLivestream: boolean
}

const ConfigureLivestreamModal = ({
  showModal,
  handleModalClose,
  onSave,
  sourceTabs,
  classNames,
  uploadImage,
  stationId,
  loadingCreateLivestream,
}: ConfigureLivestreamModalProps) => {
  const [currentSourceTab, setCurrentSourceTab] = useState(0)
  const [logoFile, setLogoFile] = useState(null)
  const [logoUrl, setLogoUrl] = useState(null)

  const [isImageUploading, setIsImageUploading] = useState(false)

  const formMethods = useForm()

  const onSubmit = (data) => {
    const payload: LivestreamInputType = {
      description: data.stationDescription,
      embedCode: data.embedCode || null,
      vimeoLiveEventId: data.vimeoLiveEventId || null,
      name: data.channelName,
      pbsStationManagerLivestreamFeedCid:
        data.pbsStationManagerLivestreamFeedCid || null,
      pbsTvssFeedCid: data.pbsTvssFeedCid || null,
      shortDescription: data.shortStationDescription,
      sourceUrl: data.sourceUrl || null,
      stationId: stationId,
    }

    if (logoFile) {
      setIsImageUploading(true)
      uploadImage([logoFile]).then((imageUrl) => {
        payload.logo = imageUrl

        onSave(payload).then(() => {
          setIsImageUploading(false)
          handleModalClose()
        })
      })
    } else {
      onSave(payload).then(() => handleModalClose())
    }
  }

  const handleImageUpload = (files: File[]) => {
    const file = files[0]
    if (file) {
      const render = new FileReader()
      render.onloadend = () => {
        const dataUrl = render.result as string
        setLogoUrl(dataUrl)
      }

      setLogoFile(file)

      render.readAsDataURL(file)
    }
  }

  const renderLivestreamSourceFields = () => {
    const selectedTabName = sourceTabs[currentSourceTab].name

    return (
      <div key={selectedTabName} className="mt-4 w-full space-y-4">
        {
          <div className="w-full">
            <Label
              name={
                selectedTabName == 'embedCode'
                  ? 'Stream Embed Code'
                  : selectedTabName == 'sourceUrl'
                    ? 'Stream URL'
                    : selectedTabName == 'vimeoLiveEventId'
                      ? 'Vimeo Live Event ID'
                      : 'Station Manager Livestream CID'
              }
              className="block text-sm font-medium text-gray-700"
            />
            <TextField
              className="my-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              name={
                selectedTabName == 'embedCode'
                  ? 'embedCode'
                  : selectedTabName == 'sourceUrl'
                    ? 'sourceUrl'
                    : selectedTabName == 'vimeoLiveEventId'
                      ? 'vimeoLiveEventId'
                      : 'pbsStationManagerLivestreamFeedCid'
              }
              defaultValue=""
              validation={{
                required: `Please provide ${
                  selectedTabName == 'embedCode'
                    ? 'an embed code.'
                    : selectedTabName == 'sourceUrl'
                      ? 'a stream URL.'
                      : selectedTabName == 'vimeoLiveEventId'
                        ? 'a Vimeo live event ID.'
                        : 'a PBS Station Manager livestream feed ID.'
                }`,
              }}
            />
            <FieldError
              className="text-red-700"
              name={
                selectedTabName == 'embedCode'
                  ? 'embedCode'
                  : selectedTabName == 'sourceUrl'
                    ? 'sourceUrl'
                    : selectedTabName == 'vimeoLiveEventId'
                      ? 'vimeoLiveEventId'
                      : 'pbsStationManagerLivestreamFeedCid'
              }
            />
            {selectedTabName == 'embedCode'
              ? embedCodeFieldDescription
              : selectedTabName == 'sourceUrl'
                ? sourceUrlFieldDescription
                : selectedTabName == 'vimeoLiveEventId'
                  ? vimeoLiveEventIdFieldDescription
                  : pbsStationManagerLivestreamFeedCidFieldDescription}
          </div>
        }
      </div>
    )
  }

  return (
    <CustomModal
      showModal={showModal}
      setShowModal={handleModalClose}
      modalWidth="max-w-3xl relative"
    >
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <Label
          name="Channel name"
          className="block text-sm font-medium text-gray-700"
        />
        <TextField
          className="my-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          name="channelName"
          defaultValue=""
          validation={{ required: 'Please provide a channel name' }}
        />
        <FieldError className="text-red-700" name="channelName" />

        <div className="mb-2 flex flex-col">
          <Label
            name="Logo"
            className="block text-sm font-medium text-gray-700"
          />
          <div className="flex flex-col space-y-2 lg:flex-row lg:space-x-4">
            <div className="mb-2 w-3/4 lg:w-1/2">
              <div className="mt-6">
                {logoUrl ? <img src={logoUrl} alt="logo" /> : <em>Not set</em>}
              </div>
            </div>
            <div className="w-full lg:w-1/2">
              <DragAndDropImageArea
                id="newChannelLogo"
                onUpload={handleImageUpload}
              />
            </div>
          </div>
        </div>

        <Label
          name="Description"
          className="block text-sm font-medium text-gray-700"
        />
        <TextAreaField
          className="my-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          name="stationDescription"
          defaultValue=""
          validation={{ required: 'Please provide a channel description' }}
        />
        <FieldError className="text-red-700" name="stationDescription" />

        <Label
          name="Short description"
          className="block text-sm font-medium text-gray-700"
        />
        <TextAreaField
          className="my-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          name="shortStationDescription"
          defaultValue=""
          validation={{
            required: 'Please provide a short channel description',
          }}
        />
        <FieldError className="text-red-700" name="shortStationDescription" />
        <Label
          name="Schedule Feed ID"
          className="block text-sm font-medium text-gray-700"
        />
        <TextField
          className="my-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          name="pbsTvssFeedCid"
          defaultValue=""
        />
        <FieldError className="text-red-700" name="pbsTvssFeedCid" />

        <TabGroup onChange={(i) => setCurrentSourceTab(i)}>
          <TabList className="mt-4 flex space-x-4 rounded-xl bg-white p-1">
            {sourceTabs.map((tab) => (
              <Tab
                key={tab.id}
                className={({ selected }) =>
                  classNames(
                    'rounded-lg px-4 py-2.5 text-sm font-medium leading-5',
                    'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                    selected
                      ? 'bg-indigo-100 text-indigo-700'
                      : 'text-gray-500 hover:bg-indigo-100/20'
                  )
                }
              >
                {tab.label}
              </Tab>
            ))}
          </TabList>
        </TabGroup>
        {renderLivestreamSourceFields()}

        <div className="mt-4 flex w-full items-center justify-center">
          <Submit className="relative inline-flex h-10 items-center justify-center overflow-hidden rounded-lg bg-indigo-500 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-600">
            {loadingCreateLivestream || isImageUploading ? <Loader /> : 'Save'}
          </Submit>
          <Button
            title="Cancel"
            outlineColor="ml-4 border-red-600 text-red-600 hover:border-red-700 hover:text-red-700"
            onClick={handleModalClose}
          />
        </div>
      </Form>
    </CustomModal>
  )
}
export default ConfigureLivestreamModal
