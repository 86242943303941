import { FaUserPlus } from 'react-icons/fa'

import { UsersType } from 'src/common/types'
import Button from 'src/components/Custom/Button/Button'
import CardSection from 'src/components/Custom/Card/CardSection'

import UserEditor from './UserEditor'

export interface UsersEditorData {
  users: UsersType[]
}

export interface UsersEditorProps {
  createUser: (inputData: {
    name: string
    email: string
    roles: string[]
    stationId: string
  }) => Promise<void>
  currentUserId: string
  data: UsersEditorData
  deactivateUser: (id: string) => Promise<void>
  loading: boolean
  onChange: (data: { users: UsersType[] }) => void
  roleType: string
  setShowNewUser: React.Dispatch<React.SetStateAction<boolean>>
  showNewUser: boolean
  stationId?: string
  updateUserData: (
    userId: string,
    inputData: {
      active?: boolean
      email?: string
      name?: string
      roles?: string[]
      stationId?: string
    }
  ) => Promise<void>
}

const UsersEditor = ({
  data,
  currentUserId,
  showNewUser,
  setShowNewUser,
  onChange,
  updateUserData,
  createUser,
  deactivateUser,
  roleType,
  loading,
  stationId,
}: UsersEditorProps) => {
  const handleDeactivateUser = (id: string) => {
    deactivateUser(id).then(() => {
      onChange({ users: data.users })
    })
  }

  const handleActivateUser = (id: string) => {
    updateUserData(id, { active: true, stationId }).then(() => {
      onChange({ users: data.users })
    })
  }

  const handleCreateUser = (inputData) => {
    createUser(inputData).then(() => {
      setShowNewUser(false)
    })
  }

  return (
    <div className="w-full">
      <CardSection title="">
        <div className="flex w-full flex-col">
          {data.users.map((user) => (
            <UserEditor
              key={user.id}
              {...user}
              updateUser={updateUserData}
              handleDeactivateUser={handleDeactivateUser}
              handleActivateUser={handleActivateUser}
              canDeactivate={user.id !== currentUserId}
              roleType={roleType}
              loading={loading}
            />
          ))}
          {showNewUser && (
            <UserEditor
              id=""
              name=""
              email=""
              roles={['']}
              stationId=""
              active={true}
              createUser={handleCreateUser}
              newUser={showNewUser}
              setNewUser={setShowNewUser}
              roleType={roleType}
              loading={loading}
            />
          )}
        </div>

        <div className="mt-4 flex justify-end">
          <Button
            title="Add user"
            icon={<FaUserPlus size="15px" />}
            outlineColor="bg-indigo-600 text-white hover:bg-indigo-800 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => setShowNewUser(true)}
            isLoading={loading}
            isDisabled={showNewUser}
          />
        </div>
      </CardSection>
    </div>
  )
}

export default UsersEditor
