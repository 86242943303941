import { useEffect, useState } from 'react'

import { Metadata, useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { useAuth } from 'src/auth'
import Card from 'src/components/Custom/Card/Card'
import ErrorMessage from 'src/components/Custom/ErrorMessage/ErrorMessage'
import HeadingLevel from 'src/components/Custom/Heading/Heading'
import Loader from 'src/components/Custom/Loader/Loader'
import {
  CREATE_STATION_USER,
  DEACTIVATE_USER,
  UPDATE_STATION_USER,
} from 'src/components/Custom/UsersEditor/queries'
import { UsersEditorData } from 'src/components/Custom/UsersEditor/UsersEditor'
import UsersEditor from 'src/components/Custom/UsersEditor/UsersEditor'
import useUserStation from 'src/lib/hooks/useUserStation'
import useWarnUnsavedChanges from 'src/lib/hooks/useWarnUnsavedChanges'

import { GET_STATION } from '../queries'

interface UserConfigurationPageState {
  stationUsers: UsersEditorData
}

type UserConfigurationPageProps = {
  id?: string
}

const UserConfigurationPage = ({ id }: UserConfigurationPageProps) => {
  const [state, setState] = useState<UserConfigurationPageState | null>(null)
  const [invalidStation, setInvalidStation] = useState(false)
  const [showNewUser, setShowNewUser] = useState(false)

  const setUnsaved = useWarnUnsavedChanges()

  const { currentUser } = useAuth()
  const userStationId = useUserStation()
  const stationId = id ?? userStationId

  const {
    data: stationData,
    loading: loadingStation,
    refetch,
  } = useQuery(GET_STATION, {
    variables: { id: stationId },
  })

  const [deactivateUser, { loading: loadingDeactivateUser }] = useMutation(
    DEACTIVATE_USER,
    {
      onCompleted: () => {
        toast.success('User deactivated successfully')
        refetch()
      },
      onError: () => {
        toast.error('Error deactivating user')
      },
    }
  )

  const [updateStationUser, { loading: loadingUpdateStationUser }] =
    useMutation(UPDATE_STATION_USER, {
      onCompleted: () => {
        toast.success('User updated successfully')
        refetch()
      },
      onError: (error) => {
        toast.error(`Update failed: ${error?.message}`)
      },
    })

  const [createStationUser, { loading: loadingCreateStationUser }] =
    useMutation(CREATE_STATION_USER, {
      onCompleted: () => {
        toast.success('User created successfully')
        refetch()
      },
      onError: (error) => {
        toast.error(`Create failed: ${error?.message}`)
      },
    })

  const handleStationUserUpdate = async (userId, inputData) => {
    await updateStationUser({
      variables: {
        id: userId,
        input: inputData,
        pageName: 'localStationManager',
      },
    })
  }

  const handleCreateStationUser = async (inputData) => {
    await createStationUser({
      variables: {
        input: { stationId: stationId, ...inputData },
        pageName: 'localStationManager',
      },
    })
  }

  const handleDeactivateUser = async (id: string) => {
    await deactivateUser({ variables: { id } })
  }

  useEffect(() => {
    setInvalidStation(stationData?.station === null)
  }, [stationData])

  useEffect(() => {
    if (stationData && stationData.station) {
      const newState = {
        stationUsers: {
          users: stationData.station.users.map((user) => ({
            id: user.id,
            name: user.name,
            roles: user.roles,
            email: user.email,
            stationId: user.stationId,
            active: user.active,
          })),
        },
      }
      setUnsaved(true)
      setState(newState)
    }
  }, [showNewUser, stationData, setUnsaved])

  const stateNotNull = () => {
    return state !== null && stationData !== undefined
  }

  return (
    <div className="w-full bg-gray-100 p-4">
      <Metadata title="Station Users" />
      {loadingStation ? (
        <Loader />
      ) : invalidStation ? (
        <ErrorMessage
          title="Not Found"
          subtitle={
            <p>
              Station with ID <code>{id}</code> not found.
            </p>
          }
        />
      ) : (
        <>
          <HeadingLevel level={1} className="mb-4">
            Station Users
          </HeadingLevel>
          {stateNotNull() && id && (
            <HeadingLevel level={2}>{stationData?.station?.name}</HeadingLevel>
          )}

          <div className="mt-5 space-y-6">
            {stateNotNull() && (
              <Card title="Station Users">
                <UsersEditor
                  data={state?.stationUsers}
                  onChange={(dataToUpdate) => {
                    setState((oldValue) => ({
                      ...oldValue,
                      ['stationUsers']: dataToUpdate,
                    }))
                  }}
                  updateUserData={handleStationUserUpdate}
                  showNewUser={showNewUser}
                  setShowNewUser={setShowNewUser}
                  currentUserId={currentUser.id}
                  createUser={handleCreateStationUser}
                  deactivateUser={handleDeactivateUser}
                  roleType="STATION"
                  loading={
                    loadingCreateStationUser ||
                    loadingDeactivateUser ||
                    loadingUpdateStationUser
                  }
                  stationId={stationId}
                />
              </Card>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default UserConfigurationPage
