import { useEffect, useState } from 'react'

import { GetStationLivestreams, Livestream } from 'types/graphql'

import { Metadata, useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Card from 'src/components/Custom/Card/Card'
import ErrorMessage from 'src/components/Custom/ErrorMessage/ErrorMessage'
import HeadingLevel from 'src/components/Custom/Heading/Heading'
import Loader from 'src/components/Custom/Loader/Loader'
import useUserStation from 'src/lib/hooks/useUserStation'
import useWarnUnsavedChanges from 'src/lib/hooks/useWarnUnsavedChanges'

import {
  CREATE_LIVESTREAM,
  DELETE_LIVESTREAM,
  GET_STATION,
  GET_STATION_LIVESTREAMS,
  UPDATE_LIVESTREAM,
} from '../queries'

import Live, { Livestreams } from './Live'

interface LivestreamManagerPageState {
  liveData: Livestreams
}

type LivestreamManagerPageProps = {
  id?: string
}

const LivestreamManagerPage = ({ id }: LivestreamManagerPageProps) => {
  const [state, setState] = useState<LivestreamManagerPageState | null>(null)
  const [invalidStation, setInvalidStation] = useState(false)

  const setUnsaved = useWarnUnsavedChanges()

  const userStationId = useUserStation()
  const stationId = id ?? userStationId

  const { data: stationData, loading: loadingStation } = useQuery(GET_STATION, {
    variables: { id: stationId },
  })

  const { data: livestreamData, refetch: refetchLivestreamData } =
    useQuery<GetStationLivestreams>(GET_STATION_LIVESTREAMS, {
      variables: { stationId: stationId },
    })

  const [updateLivestream, { loading: loadingUpdateLivestream }] = useMutation(
    UPDATE_LIVESTREAM,
    { onCompleted: () => refetchLivestreamData() }
  )

  const [deleteLivestream] = useMutation(DELETE_LIVESTREAM, {
    onCompleted: () => refetchLivestreamData(),
  })

  const [createLivestream, { loading: loadingCreateLivestream }] = useMutation(
    CREATE_LIVESTREAM,
    { onCompleted: () => refetchLivestreamData() }
  )

  const handleLivestreamUpdate = async (id, inputData, showAlerts = true) => {
    if (showAlerts) {
      await toast.promise(
        updateLivestream({
          variables: {
            id: id,
            input: inputData,
          },
        }),
        {
          loading: 'Updating livestream...',
          success: 'Livestream updated successfully',
          error: 'Error uploading livestream',
        }
      )
    } else {
      await updateLivestream({
        variables: {
          id: id,
          input: inputData,
        },
      })
    }
  }

  const handleLivestreamDelete = async (id) => {
    await toast.promise(deleteLivestream({ variables: { id: id } }), {
      loading: 'Deleting livestream...',
      success: 'Livestream deleted successfully',
      error: 'Error deleting livestream',
    })
  }

  const handleLivestreamCreation = async (input) => {
    await toast.promise(createLivestream({ variables: { input: input } }), {
      loading: 'Creating livestream...',
      success: 'Livestream created successfully',
      error: 'Error creating livestream',
    })
  }

  useEffect(() => {
    setInvalidStation(stationData?.station === null)
  }, [stationData])

  useEffect(() => {
    if (
      stationData &&
      stationData.station &&
      livestreamData &&
      livestreamData.stationLivestreams
    ) {
      const newState = {
        liveData: {
          channels: livestreamData.stationLivestreams.map(
            (item: Livestream, index: number) => ({
              id: item.id,
              label: `Channel ${index + 1}`,
              name: item.name,
              logo: item.logo,
              stationDescription: item.description,
              shortStationDescription: item.shortDescription,
              pbsTvssFeedCid: item.pbsTvssFeedCid,
              source: {
                embedCode: item.embedCode,
                sourceUrl: item.sourceUrl,
                pbsStationManager: item.pbsStationManagerLivestreamFeedCid,
                vimeoLiveEventId: item.vimeoLiveEventId,
              },
              weight: item.weight,
            })
          ),
        },
      }
      setUnsaved(true)
      setState(newState)
    }
  }, [livestreamData, stationData, setUnsaved])

  const stateNotNull = () => {
    return state !== null && stationData !== undefined
  }

  return (
    <div className="w-full bg-gray-100 p-4">
      <Metadata title="Livestream Settings" />
      {loadingStation ? (
        <Loader />
      ) : invalidStation ? (
        <ErrorMessage
          title="Station Not Found"
          subtitle={
            <p>
              Station with ID <code>{id}</code> not found.
            </p>
          }
        />
      ) : (
        <>
          <HeadingLevel level={1} className="mb-4">
            Livestream Settings
          </HeadingLevel>
          {stateNotNull() && id && (
            <HeadingLevel level={2}>{stationData.station?.name}</HeadingLevel>
          )}

          <div className="mt-5 space-y-6">
            {stateNotNull() && (
              <Card title="Live">
                <Live
                  data={state?.liveData}
                  onChange={(dataToUpdate) => {
                    setState((oldValue) => ({
                      ...oldValue,
                      ['liveData']: dataToUpdate,
                    }))
                  }}
                  updateLivestream={handleLivestreamUpdate}
                  createLivestream={handleLivestreamCreation}
                  deleteLivestream={handleLivestreamDelete}
                  stationId={stationId}
                  loadingCreateLivestream={loadingCreateLivestream}
                  loadingUpdateLivestream={loadingUpdateLivestream}
                />
              </Card>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default LivestreamManagerPage
